<script>
import DaybookAppBarMixin from "./DaybookAppBarMixin";
import EmployeeAction from "@/components/employees/EmployeeAction.js";
import { hasRouteDay } from "@/mixins/routerParams";

export default {
  name: "DaybookAppBarDesktop",
  components: { EmployeeAction },
  mixins: [DaybookAppBarMixin, hasRouteDay],
  methods: {
    handleClick(route) {
      this.$router.push(route);
    },
  },
};
</script>

<template>
  <VxAppBar title="Daybook">
    <template #title>
      <VxAppBarDate v-model="dayDate" />
    </template>
    <template #overflowExtras>
      <v-btn
        v-if="inAssignMode"
        text
        color="primary"
        @click="
          $router.replace({
            query: { assignMode: undefined },
            preserveQuery: true,
          })
        "
      >
        Exit Assign Mode
      </v-btn>
    </template>
    <template #rightMenuItems>
      <v-list-item
        v-if="!inAssignMode"
        :key="'assign-task'"
        ripple
        @click="
          $router.replace({ query: { assignMode: true }, preserveQuery: true })
        "
      >
        <v-list-item-title v-text="'Assign tasks'" />
      </v-list-item>
      <template v-for="(item, idx) in computedItems">
        <EmployeeAction
          v-if="item.managerOnly"
          :key="idx"
          v-slot="{ handleAction, isDisabled, listeners }"
          check-manager
        >
          <v-list-item
            :key="idx"
            ripple
            v-on="listeners"
            @click="() => handleAction(() => handleClick(item.to))"
          >
            <v-list-item-title
              :class="{ 'text--disabled': isDisabled }"
              v-text="item.title"
            />
            <v-list-item-subtitle
              v-if="item.subtitle"
              :class="{ 'text--disabled': isDisabled }"
              v-text="item.subtitle"
            />
            <v-list-item-action v-if="item.icon">
              <v-icon v-text="item.icon" />
            </v-list-item-action>
          </v-list-item>
        </EmployeeAction>
        <v-list-item v-else :key="idx" ripple :to="item.to">
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle" />
          <v-list-item-action v-if="item.icon">
            <v-icon v-text="item.icon" />
          </v-list-item-action>
        </v-list-item>
      </template>
    </template>
  </VxAppBar>
</template>
