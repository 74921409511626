import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarDate from "@/components/vx/VxAppBarDate";
import RightMenu from "@/components/RightMenu";
import { hasRouteDay } from "@/mixins/routerParams";

import { format, parseISO } from "date-fns";

const daybookAppBarMixins = {
  mixins: [hasRouteDay],
};

const daybookAppBarComponents = {
  components: {
    VxAppBar,
    VxAppBarDate,
    RightMenu,
  },
};

const daybookAppBarComputedProperties = {
  computed: {
    inAssignMode() {
      if (!this.$route.query.assignMode) return false;
      return this.$route.query.assignMode;
    },
    // TODO: handle manager content
    computedItems() {
      return [
        {
          to: { name: "daybook.setup" },
          title: "Daybook setup",
          managerOnly: true,
        },
        { to: { name: "goal.setup" }, title: "Goal setup", managerOnly: true },
      ].filter(Boolean);
    },
  },
};

export default {
  mixins: [
    daybookAppBarComponents,
    daybookAppBarComputedProperties,
    daybookAppBarMixins,
  ],
};
