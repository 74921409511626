<template>
  <v-toolbar-items>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      data-testid="appBarDatePrevBtn"
      text
      icon
      class="mr-0 pr-0"
      @click.native="prev"
    >
      <v-icon size="16px">$vuetify.icons.prev</v-icon>
    </v-btn>
    <v-menu
      v-model="picker"
      origin="top center"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn text ripple class="title_menu px-0" v-on="on">
          <v-toolbar-title data-testid="appBarDateTitle">
            {{ displayValue }}
          </v-toolbar-title>
        </v-btn>
      </template>
      <v-date-picker
        v-model="localValue"
        no-title
        scrollable
        :type="monthly ? 'month' : 'date'"
        data-testid="appBarDateField"
        @change="picker = false"
      />
    </v-menu>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      data-testid="appBarDateNextBtn"
      text
      icon
      class="ml-0 pl-0"
      @click.native="next"
    >
      <v-icon size="16px">$vuetify.icons.next</v-icon>
    </v-btn>
  </v-toolbar-items>
</template>

<script>
/**
 * Simple Date picker for use in the App Bar.
 * Displays date according to our usage of the Material spec.
 *
 * v-model is a Date object instead of that iso8601 nonsense
 *
 * TODO: support monthly and weekly date navigation
 */

import {
  addDays,
  addWeeks,
  addMonths,
  format,
  parseISO,
  isDate,
} from "date-fns";
import { bizDay, bizWeek, bizMonth } from "@/utils/datetime";
import { hasRouteDay } from "@/mixins/routerParams";

export default {
  name: "VxAppBarDate",
  mixins: [hasRouteDay],
  props: {
    value: {
      type: Date,
      required: true,
    },
    weekly: {
      type: Boolean,
      default: false,
    },
    monthly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    picker: false,
  }),
  computed: {
    localValue: {
      get: function () {
        return format(this.value, "yyyy-MM-dd");
      },
      // val can be string or Date
      set: function (newValue) {
        this.$emit("input", isDate(newValue) ? newValue : parseISO(newValue));
      },
    },
    displayValue() {
      // used here to update the display value when the day changes,
      // since it won't update on its own because the route doesn't change
      this.CURRENT_DAY;
      if (this.monthly) {
        return bizMonth(this.value);
      }
      if (this.weekly) {
        return bizWeek(this.value);
      }
      return bizDay(this.value);
    },
  },
  methods: {
    jump(num) {
      if (this.monthly) {
        this.localValue = addMonths(this.value, num);
      } else if (this.weekly) {
        this.localValue = addWeeks(this.value, num);
      } else {
        this.localValue = addDays(this.value, num);
      }
    },
    prev() {
      this.jump(-1);
    },
    next() {
      this.jump(1);
    },
  },
};
</script>
